<template>
  <div>
    <q-form ref="editForm">
      <div class="row">
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
          <!-- 개정 -->
          <c-card title="LBLREV" class="cardClassDetailForm revisionLayout" topClass="topcolor-orange" v-if="popupParam.psiDeviceEquipmentId">
            <template slot="card-detail">
              <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                <div class="row">
                  <div class="col-1">
                    <!-- 개정번호 -->
                    <c-text
                      :required="editable && isRevision"
                      :editable="editable && isRevision"
                      label="LBLREVNO"
                      name="revisionNum"
                      v-model="data.revisionNum">
                    </c-text>
                  </div>
                  <div class="col-2">
                    <!-- 개정일시 -->
                    <c-text
                      :editable="false"
                      label="LBLREVPERIOD"
                      name="regDtStr"
                      v-model="data.regDtStr">
                    </c-text>
                  </div>
                  <div class="col-1">
                    <!-- 개정자 -->
                    <c-text
                      :editable="false"
                      label="LBLREVUSER"
                      name="regUserName"
                      v-model="data.regUserName">
                    </c-text>
                  </div>
                  <div class="col-4">
                    <!-- MOC번호 -->
                    <c-moc 
                      :editable="editable && isRevision"
                      :isSubmit="saveCallData"
                      :document.sync="data"
                      :documentId="popupParam.psiDeviceEquipmentId"
                      documentTitle="deviceName"
                      mocRelatedTaskCd="RT00000001"
                      label="LBLMOCNO"
                      name="sopMocId"
                      v-model="data.sopMocId"
                    />
                  </div>
                  <div class="col-4">
                    <!-- 개정사유 -->
                    <c-text
                      :required="editable && isRevision"
                      :editable="editable && isRevision"
                      label="LBLREVREASON"
                      name="revisionContent"
                      v-model="data.revisionContent">
                    </c-text>
                  </div>
                </div>
              </div>
              <!-- <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6" style="margin-top: -18px !important;" id="revdiv">
                <c-table
                  ref="revision"
                  title="개정이력"
                  tableId="revision"
                  topBorderClass="topcolor-orange"
                  :columns="gridrev.columns"
                  :data="gridrev.data"
                  gridHeight="150px"
                  :columnSetting="false"
                  :expandAll="false"
                  :isFullScreen="false"
                  :usePaging="false"
                  :hideBottom="true"
                  :filtering="false"
                  @rowClick="rowClickRev"
                >
                </c-table>
              </div> -->
            </template>
          </c-card>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
          <!-- 장치 및 설비 기본정보 -->
          <c-card title="LBL0010410" class="cardClassDetailForm">
            <template slot="card-select">
              <!-- 개정이력 -->
              <c-select
                :editable="!isRevision"
                v-if="popupParam.psiDeviceEquipmentId"
                :comboItems="gridrev.data"
                type="custom"
                typetext="LBL0010565"
                itemText="revisionNum"
                itemValue="psiDeviceEquipmentId"
                name="selectedPsiDeviceEquipmentId"
                label=""
                v-model="selectedPsiDeviceEquipmentId"
                @input="rowClickRev"
              ></c-select>
            </template>
            <template slot="card-button">
              <q-btn-group outline >
                <q-btn
                  icon="help"
                  color="deep-purple-6"
                  text-color="white"
                  class="custom-btn"
                  align="center"
                  round>
                  <q-tooltip anchor="bottom left" self="top left">
                    <div class="tooltipCustomTop">
                      <!-- (주) -->
                      {{ $label('LBL0010385') }}
                    </div>
                    <div class="tooltipCustom">
                    <!-- ① 압력용기, 증류탑, 반응기, 열교환기, 탱크류 등 고정기계에 해당합니다. -->
                    {{ $message('MSG0010032') }}<br>
                    <!-- ② 부속물은 증류탑의 충진물, 데미스터(Demister), 내부의 지지물 등을 말합니다. -->
                    {{ $message('MSG0010033') }}<br>
                    <!-- ③ 용량에는 장치 및 설비의 직경 및 높이 등을 기재합니다. -->
                    {{ $message('MSG0010034') }}<br>
                    <!-- ④ 열교환기류는 동체측과 튜브측을 구별하여 기재합니다. -->
                    {{ $message('MSG0010035') }}<br>
                    <!-- ⑤ 자켓이 있는 압력용기류는 동체측과 자켓측을 구별하여 기재합니다. -->
                    {{ $message('MSG0010036') }}
                    </div>
                  </q-tooltip>
                </q-btn>
                <!-- 개정 -->
                <c-btn
                  v-show="(editable && popupParam.psiDeviceEquipmentId && !isRevision) && isEnalbed"
                  label="LBLREV"
                  icon="restart_alt"
                  @btnClicked="SetRevision" />
                <!-- 개정취소 -->
                <c-btn
                  v-show="(editable && popupParam.psiDeviceEquipmentId && isRevision) && isEnalbed"
                  label="LBLREVCANCEL"
                  icon="restart_alt"
                  @btnClicked="CancelRevision" />
                <!-- 삭제 -->
                <c-btn
                  v-if="editable && popupParam.psiDeviceEquipmentId && isEnalbed"
                  label="LBLREMOVE"
                  icon="remove"
                  @btnClicked="deleteData"/>
                <!-- 저장 -->
                <c-btn
                  v-if="editable && isEnalbed"
                  :url="saveUrl"
                  :isSubmit="isSave"
                  :param="data"
                  :mappingType="mappingType"
                  label="LBLSAVE"
                  icon="save"
                  @beforeAction="saveData"
                  @btnCallback="saveCallback" />
              </q-btn-group>
            </template>
            <template slot="card-detail">
              <div class="col-4">
                <!-- 장치번호 -->
                <c-text
                  :required="true"
                  :editable="editable"
                  label="LBL0010389"
                  name="equipmentNo"
                  v-model="data.equipmentNo">
                </c-text>
              </div>
              <div class="col-4">
                <!-- 장치명 -->
                <c-text
                  :required="true"
                  :editable="editable"
                  label="LBL0010390"
                  name="deviceName"
                  v-model="data.deviceName">
                </c-text>
              </div>
              <div class="col-4">
                <!-- 장치분류 -->
                <c-select
                  :required="true"
                  :editable="editable && !popupParam.psiDeviceEquipmentId"
                  codeGroupCd="DEVICE_TYPE_CD"
                  type="edit"
                  itemText="codeName"
                  itemValue="code"
                  name="deviceTypeCd"
                  label="LBL0010411"
                  v-model="data.deviceTypeCd"
                ></c-select>
              </div>
              <div class="col-3">
                <!-- 설비 -->
                <c-equip
                  :editable="editable"
                  label="LBLEQUIP"
                  name="equipmentCd"
                  v-model="data.equipmentCd">
                </c-equip>
              </div>
              <div class="col-3">
                <c-plant
                  :required="true"
                  :editable="editable"
                  type="edit"
                  name="plantCd"
                  v-model="data.plantCd" />
              </div>
              <div class="col-3">
                <!-- 관련공정 -->
                <c-process
                  :editable="editable"
                  label="LBL0001705"
                  multiple="single"
                  name="processCd"
                  v-model="data.processCd">
                </c-process>
              </div>
              <div class="col-3">
                <!-- 관리부서 -->
                <c-dept
                  :editable="editable"
                  label="LBL0001721"
                  name="deptCd"
                  v-model="data.deptCd">
                </c-dept>
              </div>
            </template>
          </c-card>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" style="padding-top: 20px !important;">
          <!-- 첨부파일 -->
          <c-upload 
            :attachInfo="attachInfo"
            :editable="editable"
            label="LBLATTACHMENT">
          </c-upload>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" style="margin-top: -20px !important;">
          <c-tab
            type="tabcard"
            :tabItems.sync="tabItems"
            :inlineLabel="true"
            v-model="tab"
            align="left"
          >
            <template v-slot:default="tab">
              <component
                :is="tab.component"
                :key="tab.tabKey"
                :param="tab.param"
                :item.sync="tab.item"
              />
            </template>
          </c-tab>
        </div>
      </div>
      <c-dialog :param="popupOptions"></c-dialog>
    </q-form>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
import { uid } from 'quasar'

export default {
  name: 'device-machine-detail',

  props: {
    popupParam: {
      type: Object,
      default: () => ({
        psiDeviceEquipmentId: '',
        psiEquipmentCd: '',
      }),
    },
    contentHeight: null,
  },
  data() {
    return {
      attachInfo: {
        isSubmit: '',
        taskClassCd: 'PSI_DEVICEEQUIPMENT',
        taskKey: '',
        beforeTaskKey: '',
        isRev: false,
      },
      selectedPsiDeviceEquipmentId: null,
      tab: 'devicePartCd1',
      tabItems: [],
      data: {
        psiDeviceEquipmentId: '',  // 장치일련번호
        plantCd: '',  // 사업장코드
        equipmentNo: '',  // 장치번호
        deviceName: '',  // 장치명
        equipmentCd: '',  // 설비코드
        deviceTypeCd: null,  // 장치분류
        deviceTypeName: '',  // 장치분류
        processCd: '',
        deptCd: '',
        revisionNum: '',
        groupId: '',
        sysRevision: 1,
        revisionContent: '제정',
        regDtStr: '',
        regUserName: '',
        sopMocId: '',
        detailItems: [],
      },
      popupOptions: {
        target: null,
        title: '',
        visible: false,
        width: '90%',
        top: '10px',
        param: {},
        closeCallback: null,
      },
      gridrev: {
        columns: [
          {
            name: 'revisionNum',
            field: 'revisionNum',
            // 개정번호
            label: 'LBLREVNO',
            align: 'center',
            style: 'width: 80px',
            sortable: true,
          },
          {
            name: 'revisionContent',
            field: 'revisionContent',
            // 개정사유
            label: 'LBLREVREASON',
            align: 'left',
            sortable: true,
          },
          {
            name: 'regDtStr',
            field: 'regDtStr',
            // 개정일시
            label: 'LBLREVPERIOD',
            style: 'width: 150px',
            align: 'center',
            sortable: true,
          },
          {
            name: 'regUserName',
            field: 'regUserName',
            // 개정자
            label: 'LBLREVUSER',
            align: 'center',
            style: 'width: 80px',
            sortable: true,
          },
        ],
        data: [],
      },
      saveUrl: transactionConfig.psi.pfi.device.mst.insert.url,
      mappingType: 'POST',
      insertUrl: '',
      updateUrl: '',
      deleteUrl: '',
      revlistUrl: '',
      editable: true,
      isSave: false,
      isdelete: false,
      isRevision: false,
      isEnalbed: true,
      saveCallData: '',
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  watch: {
    'data.deviceTypeCd': function() {
      this.getDetail();
    }
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      if (this.popupParam.popeditable === undefined) {
        this.editable = this.$route.meta.editable;
        this.isEnalbed = true;
      } else if (this.popupParam.popeditable === true) {
        this.editable = this.$route.meta.editable;
        this.isEnalbed = true;
      } else {
        this.editable = false;
        this.isEnalbed = false;
      }
      this.detailUrl = selectConfig.psi.pfi.device.get.url;
      this.insertUrl = transactionConfig.psi.pfi.device.mst.insert.url;
      this.updateUrl = transactionConfig.psi.pfi.device.mst.update.url;
      this.deleteUrl = transactionConfig.psi.pfi.device.mst.delete.url;
      this.revlistUrl = selectConfig.psi.pfi.device.list.url + '/revs';
      this.getData();
    },
    getData() {
      this.isRevision = false;
      if (this.popupParam.psiDeviceEquipmentId) {
        this.$set(this.attachInfo, 'taskKey', this.popupParam.psiDeviceEquipmentId)
        this.getDetailData(this.popupParam.psiDeviceEquipmentId, true);
        this.selectedPsiDeviceEquipmentId = this.popupParam.psiDeviceEquipmentId
      } else {
        if (this.popupParam.psiEquipmentCd) {
          this.data.equipmentCd = this.popupParam.psiEquipmentCd;
        }
      }
    },
    getDetail() {
      this.tabItems = [];
      this.$comm.getComboItems('DEVICE_PART_CD').then(_result => {
        _result = this.$_.filter(_result, {attrVal1 : this.data.deviceTypeCd });
        if(this.data.detailItems.length == 0) {
          this.$_.forEach(_result, _item => {
            this.data.detailItems.push({
              psiDeviceEquipmentId: '',  // 장치일련번호
              devicePartCd: _item.code,  // 장치구분
              inMaterials: '',  // 내용물
              volume: '',  // 용량
              pressOperation: '',  // 압력(Mpa)-운전
              pressDesign: '',  // 압력(Mpa)-설계
              tempOperation: '',  // 온도(℃)-운전
              tempDesign: '',  // 온도(℃)-설계
              materialMain: '',  // 사용재질-본체
              materialSubPart: '',  // 사용재질-부속품
              materialGasket: '',  // 사용재질-개스킷
              weldingEfficiency: '',  // 용접효율
              calculateThick: '',  // 계산두께(mm)
              corrosionAllowance: '',  // 부식여유(mm)
              useThick: '',  // 사용두께(mm)
              afterHeatTreatYn: 'N',  // 후열처리여부
              nondestructMethod: '',  // 비파괴검사방법
              nondestructPercent: '',  // 비파괴검사율(%)
              applicableLaws: '',  // 적용법령
              regUserId: this.$store.getters.user.userId,
              chgUserId: this.$store.getters.user.userId,
            });
          });
        }
        if (_result.length == 1) {
          let _data = this.data.detailItems[0]
          this.tabItems.push({ name: 'devicePartCd1', icon: 'info', label: '장치 및 설비 상세', param: {psiDeviceEquipmentId:this.popupParam.psiDeviceEquipmentId, devicePartCd: _result[0].code, editable:this.editable, devicePartName:_result[0].codeName}, component: () => import(`${'./deviceEquipmentDetailInfo.vue'}`), item: _data, tabKey: uid() });
        } else {
          let cnt = 0;
          this.$_.forEach(_result, item => {
            let _data = this.data.detailItems[cnt]
            this.tabItems.push({ name: 'devicePartCd' + (cnt + 1), icon: 'info', label:item.codeName, param: {psiDeviceEquipmentId:this.popupParam.psiDeviceEquipmentId, devicePartCd: item.code, editable:this.editable, devicePartName:item.codeName}, component: () => import(`${'./deviceEquipmentDetailInfo.vue'}`), item: _data, tabKey: uid() });
            cnt++;
          });
        }
      });
    },
    getDetailData(_psiDeviceEquipmentId, _check) {
      this.$http.url = this.$format(this.detailUrl, _psiDeviceEquipmentId);
      this.$http.type = 'GET';
      this.$http.request((_result) => {
        this.data = _result.data;
        // 상세팝업 최초작성/최근수정 정보 노출
        this.$emit('setRegInfo', _result.data)
        this.isRevision = false;
        if (_check) {
          this.getRevList(_result.data.groupId);
        }
        if(_result.data.psiDeviceEquipmentId == this.popupParam.psiDeviceEquipmentId && this.isEnalbed) {
          this.editable = this.$route.meta.editable;
        } else {
          this.editable = false;
        }
      },);
    },
    saveData() {
      if (this.popupParam.psiDeviceEquipmentId) {
        this.saveUrl = this.updateUrl;
        this.mappingType = 'PUT';
      } else {
        this.saveUrl = this.insertUrl;
        this.mappingType = 'POST';
      }
      let saveMsg = 'MSGSAVE';  // 저장하시겠습니까?
      if (this.isRevision) {
        saveMsg = 'MSGREVSAVE';
        // 현재버전을 개정하여 저장하시겠습니까?
      }

      this.$refs['editForm'].validate().then(_result => {
        if (_result) {
          window.getApp.$emit('CONFIRM', {
            title: 'LBLCONFIRM',  // 확인
            message: saveMsg,
            // TODO : 필요시 추가하세요.
            type: 'info', // success / info / warning / error
            // 확인 callback 함수
            confirmCallback: () => {
              this.data.regUserId = this.$store.getters.user.userId;
              this.data.chgUserId = this.$store.getters.user.userId;
              this.isSave = !this.isSave;
              this.isdelete = false;
              // 개정시
              if (this.isRevision) {
                this.saveUrl = this.insertUrl;
                this.mappingType = 'POST';
                this.data.sysRevision = parseInt(this.data.sysRevision) + 1;
              }
            },
            // 취소 callback 함수
            cancelCallback: () => {
            },
          });
        } else {
          window.getApp.$emit('APP_VALID_ERROR');
        }
      });
    },
    deleteData() {
      window.getApp.$emit('CONFIRM', {
        title: 'LBLCONFIRM',  // 확인
        message: 'MSGREMOVE', // 삭제하시겠습니까?,
        // TODO : 필요시 추가하세요.
        type: 'warning', // success / info / warning / error
        // 확인 callback 함수
        confirmCallback: () => {
          this.$http.url = this.$format(this.deleteUrl, this.popupParam.psiDeviceEquipmentId);
          this.$http.type = 'DELETE';
          this.$http.request(() => {
            this.isdelete = !this.isdelete;
            this.popupParam.psiDeviceEquipmentId = null;
            this.getDetailData(null, false);
            window.getApp.$emit('APP_REQUEST_SUCCESS');
            this.$emit('closePopup');
          },);
        },
        // 취소 callback 함수
        cancelCallback: () => {
        },
      });
    },
    saveCallback(result) {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.popupParam.psiDeviceEquipmentId = result.data.psiDeviceEquipmentId
      this.$set(this.attachInfo, 'taskKey', result.data.psiDeviceEquipmentId)
      this.$set(this.attachInfo, 'isSubmit', uid())
      this.saveCallData = uid();
      /**
       * 첨부파일 셋팅
       */
      this.$set(this.attachInfo, 'beforeTaskKey', '')
      this.$set(this.attachInfo, 'isRev', false)
      this.getData();
      if (this.mappingType == 'POST') {
        this.getDetail();
      }
    },
    reloadTabItem(_equipmentCd) {
      this.popupParam.equipmentCd = _equipmentCd;
      this.tabItems = this.tabItems.concat(this.addTabItems);
    },
    SetRevision() {
      this.$http.url = selectConfig.com.revision.getversion;
      this.$http.param = {revisionNum: this.data.revisionNum}
      this.$http.type = 'GET';
      this.$http.request((_result) => {
        this.data.revisionNum = _result.data;
        this.data.revisionContent = '';
        this.data.regDtStr = '';
        this.data.regUserName = '';
        this.data.sopMocId = '';
        this.isRevision = true;
        /**
         * 첨부파일 셋팅
         */
        this.$set(this.attachInfo, 'taskKey', '')
        this.$set(this.attachInfo, 'beforeTaskKey', this.popupParam.psiDeviceEquipmentId)
        this.$set(this.attachInfo, 'isRev', true)
      });
    },
    CancelRevision() {
      this.isRevision = false;
      /**
       * 첨부파일 셋팅
       */
      this.$set(this.attachInfo, 'taskKey', this.popupParam.psiDeviceEquipmentId)
      this.$set(this.attachInfo, 'beforeTaskKey', '')
      this.$set(this.attachInfo, 'isRev', false)
      this.getData();
    },
    getRevList(_groupId) {
      this.$http.url = this.revlistUrl;
      this.$http.type = 'GET';
      this.$http.param = {groupId: _groupId};
      this.$http.request((_result) => {
        this.gridrev.data = _result.data;
      },);
    },
    rowClickRev(row) {
      if (row) {
        if (row.psiDeviceEquipmentId != this.data.psiDeviceEquipmentId) {
          this.selectedPsiDeviceEquipmentId = row;
          this.getDetailData(this.selectedPsiDeviceEquipmentId, false);
          this.$set(this.attachInfo, 'taskKey', this.selectedPsiDeviceEquipmentId)
        }
      }
    },
  }
};
</script>
